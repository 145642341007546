import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  align-items: center;
  gap: 4px;
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  text-align: right;
`;

export const ColorInput = styled.input`
  appearance: none;
  border: 1px solid theme.colors.ultraLight;
  border-radius: 4px;
  cursor: pointer;
  width: 36px;
  height: 26px;
`;
