// ImageCrop.tsx
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageZoom from '../ImageZoom/index';

interface ImageCropProps {
  src: string;
  crop: Crop;
  onCropChange: (crop: Crop) => void;
  zoom: number;
  setZoom: (zoom: number | ((prevZoom: number) => number)) => void;
  isPinching: boolean;
  setIsPinching: (isPinching: boolean) => void;
  onCropComplete: (crop: Crop) => void;
  imageRef: React.RefObject<HTMLImageElement>;
  isCropMode: boolean;
}

const ImageCrop: React.FC<ImageCropProps> = ({
  src,
  crop,
  onCropChange,
  zoom,
  setZoom,
  isPinching,
  setIsPinching,
  onCropComplete,
  imageRef,
}) => {
  return (
    <ImageZoom
      zoom={zoom}
      setZoom={setZoom}
      isPinching={isPinching}
      setIsPinching={setIsPinching}
    >
      <div
        style={{
          position: 'relative',
          width: 'fit-content',
          margin: '0 auto',
          overflow: 'hidden',
        }}
      >
        <ReactCrop
          crop={crop}
          onChange={onCropChange}
          onComplete={onCropComplete}
          keepSelection={true}
          disabled={isPinching}
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        >
          <img
            ref={imageRef}
            src={src}
            alt="Preview"
            style={{
              transform: `scale(${zoom})`,
              transformOrigin: 'center',
            }}
          />
        </ReactCrop>
      </div>
    </ImageZoom>
  );
};

export default ImageCrop;
