// SnapNoteCard.tsx
import { useState, useRef, useEffect, useCallback } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { FaArrowRight } from 'react-icons/fa';
import { Modal, Card, Box, RotatableIcon } from '../../../storyBook';
import { SnapNote } from '../../types';
import SubMenu from '../../Timeline/SubMenu';
import useSnapNote from '../../hooks/useSnapNote';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Text, Flex } from '../../../storyBook';
import { formatDate } from '../../Utils/helper';
import Loader from '../../_common/Loader';
import { GoStarFill } from 'react-icons/go';
import useTimeline from '../../hooks/useTimeline';
import { useLocation } from 'react-router-dom';
import ControlPanel from './ControlPanel';
import { Crop } from 'react-image-crop';
import {
  CardStyle,
  Label,
  ImageGallery,
  Image,
  LeftArrow,
  RightArrow,
  PreviewImage,
  RotableContainer,
  DownloadIcon,
  ImageContainer,
  ImageOverlay,
} from './style';
import theme from '../../../theme';
import ImageCrop from './ImageCrop';

const SnapNoteCard = ({
  snapNote,
  toggleStar,
}: {
  snapNote: SnapNote;
  toggleStar: () => void;
}) => {
  const { deleteSnapNote, updateSnapNoteImage, loading } = useSnapNote();
  const galleryRef = useRef<HTMLDivElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [originalImage, setOriginalImage] = useState<string | null>(null); // Store original image

  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 25,
    height: 25,
    x: 35,
    y: 30,
  });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const [zoom, setZoom] = useState(1); // Zoom state for scaling
  const [isPinching, setIsPinching] = useState(false);

  const [isCropMode, setIsCropMode] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [hideEditIcon, setHideEditIcon] = useState(true);
  const [editingImagePath, setEditingImagePath] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const { addSnapnoteToTimeline } = useTimeline();

  const handleEditClick = () => {
    setIsEditPanelOpen(true);
    setHideEditIcon(false);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const timelineId = query.get('timelineId');

  const history = useHistory();
  const path = useRouteMatch();

  const { label, content, dateCreated } = snapNote;

  if (loading) {
    return <Loader isLoading={true} />;
  }

  const updateArrowVisibility = () => {
    const gallery = galleryRef.current;
    if (gallery) {
      setShowLeftArrow(gallery.scrollLeft > 0);
      setShowRightArrow(
        gallery.scrollLeft < gallery.scrollWidth - gallery.clientWidth
      );
    }
  };

  useEffect(() => {
    const gallery = galleryRef.current;
    if (gallery) {
      updateArrowVisibility();
      const handleScroll = () => updateArrowVisibility();
      gallery.addEventListener('scroll', handleScroll);
      return () => gallery.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const gallery = galleryRef.current;
    if (gallery) {
      if (direction === 'left') {
        gallery.scrollBy({ left: -gallery.offsetWidth, behavior: 'smooth' });
      } else {
        gallery.scrollBy({ left: gallery.offsetWidth, behavior: 'smooth' });
      }
    }
  };

  const handleImageClick = (index: number) => {
    const imageUrl = (snapNote.imageUrls ?? [])[index];
    const imagePath = (snapNote.images ?? [])[index];
    setPreviewImage(imageUrl);
    setOriginalImage(imageUrl);
    setEditingImagePath(imagePath);
  };

  const downloadImage = (imageUrl: string) => {
    const downloadUrl = croppedImage || imageUrl;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', label || 'downloadedImage.jpg');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closePreview = () => {
    setPreviewImage(null);
    setIsCropMode(false);
  };

  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };

  const handleDelete = async () => {
    if (snapNote._id) {
      await deleteSnapNote(snapNote._id);
    }
  };

  const handleEdit = () => {
    history.push(`${path.url}/edit/${snapNote._id}`);
  };

  // Handle crop change
  const onCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
  };

  // Generate the cropped image once cropping is complete
  const onCropComplete = useCallback((crop: Crop) => {
    setCompletedCrop(crop);
  }, []);

  // Convert pixel values to millimeters based on DPI
  const convertPixelsToMm = (pixels: number, dpi: number = 96) =>
    (pixels / dpi) * 25.4;

  const cropSize =
    crop.width && crop.height && isCropMode
      ? `${convertPixelsToMm(crop.width).toFixed(1)} x ${convertPixelsToMm(
          crop.height
        ).toFixed(1)} mm`
      : '';

  const saveCroppedImage = () => {
    if (!completedCrop || !previewImage || !snapNote._id || !editingImagePath)
      return;

    const image = new window.Image();
    image.crossOrigin = 'anonymous';
    image.src = previewImage;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Get natural dimensions
      const naturalWidth = image.naturalWidth;
      const naturalHeight = image.naturalHeight;

      // Get displayed dimensions
      const displayedWidth = imageRef.current
        ? imageRef.current.width
        : naturalWidth;
      const displayedHeight = imageRef.current
        ? imageRef.current.height
        : naturalHeight;

      // Calculate scaling factors
      const scaleX = naturalWidth / displayedWidth;
      const scaleY = naturalHeight / displayedHeight;

      // Adjust the crop coordinates
      const pixelCrop = {
        x: completedCrop.x * scaleX,
        y: completedCrop.y * scaleY,
        width: completedCrop.width * scaleX,
        height: completedCrop.height * scaleY,
      };

      // Set canvas dimensions
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      try {
        // Draw the cropped image onto the canvas
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );

        // Convert the canvas content to a blob and handle the rest
        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File([blob], 'cropped-image.png', {
              type: 'image/png',
            });

            const formData = new FormData();
            formData.append('image', file);
            formData.append('imagePath', editingImagePath);

            if (snapNote._id) {
              await updateSnapNoteImage(snapNote._id, formData);
            } else {
              console.error('snapNote._id is undefined');
            }

            setIsCropMode(false);
            setIsEditPanelOpen(false);
            setPreviewImage(null);
            setEditingImagePath(null);
          } else {
            console.error('Failed to get blob from canvas');
          }
        }, 'image/png');
      } catch (error) {
        console.error('Failed to save cropped image: ', error);
      }
    };

    image.onerror = () => {
      console.error('Image could not be loaded for saving.');
    };
  };

  const resetToOriginal = () => {
    setPreviewImage(originalImage);
    setCroppedImage(null);
    setIsCropMode(false);
  };

  return (
    <CardStyle>
      <RotableContainer>
        <RotatableIcon
          _id={snapNote._id || ''}
          onToggle={handleMenuToggle}
          isOpen={openMenuId === snapNote._id}
        />
        {openMenuId === snapNote._id && (
          <SubMenu
            id={snapNote._id}
            timelineId={timelineId || ''}
            showEditOption={true}
            snapNote
            onEdit={handleEdit}
            onDelete={handleDelete}
            setOpenMenuId={setOpenMenuId}
            addToTimeline
            addSnapnoteToTimeline={addSnapnoteToTimeline}
          />
        )}
      </RotableContainer>

      <Label>
        <Flex flexDirection="column">
          <Text as="small">{formatDate(dateCreated)}</Text>
          <Flex alignItems="center" justifyContent="space-between" mr={1}>
            {label}

            <GoStarFill
              size={20}
              color={
                snapNote.isStarred ? theme.colors.pending : theme.colors.medium
              }
              style={{ cursor: 'pointer' }}
              onClick={toggleStar}
            />
          </Flex>
        </Flex>
      </Label>
      {content && (
        <Box mb={3}>
          <Card background={theme.colors.ultraLight} padding={theme.spacing.sm}>
            {content}
          </Card>
        </Box>
      )}

      {showLeftArrow && (
        <LeftArrow onClick={() => scroll('left')}>
          <FaArrowLeft />
        </LeftArrow>
      )}
      <ImageGallery ref={galleryRef}>
        {snapNote.imageUrls?.map((imageUrl, index) => (
          <ImageContainer key={index}>
            <Image
              src={imageUrl}
              alt={`SnapNote Image ${index + 1}`}
              onClick={() => handleImageClick(index)}
            />
            <ImageOverlay>
              <DownloadIcon onClick={() => downloadImage(imageUrl)} />
            </ImageOverlay>
          </ImageContainer>
        ))}
      </ImageGallery>

      {showRightArrow && (
        <RightArrow onClick={() => scroll('right')}>
          <FaArrowRight />
        </RightArrow>
      )}

      <Modal
        onClose={closePreview}
        isOpen={!!previewImage}
        isEditIcon
        hideEditIcon={hideEditIcon}
        handleEditClick={handleEditClick}
        panelBackground
      >
        {previewImage && !isCropMode && (
          <PreviewImage src={previewImage} alt="Preview" />
        )}

        {previewImage && isCropMode && (
          <ImageCrop
            src={previewImage}
            crop={crop}
            onCropChange={onCropChange}
            zoom={zoom}
            setZoom={setZoom}
            isPinching={isPinching}
            setIsPinching={setIsPinching}
            onCropComplete={onCropComplete}
            imageRef={imageRef}
            isCropMode={isCropMode}
          />
        )}

        {isEditPanelOpen && (
          <ControlPanel
            setIsEditPanelOpen={setIsEditPanelOpen}
            setHideEditIcon={setHideEditIcon}
            saveCroppedImage={saveCroppedImage}
            resetToOriginal={resetToOriginal}
            setIsCropMode={setIsCropMode}
            isCropMode={isCropMode}
            cropSize={cropSize}
          />
        )}
      </Modal>
    </CardStyle>
  );
};

export default SnapNoteCard;
