import React from 'react';
import { Box, Button, Flex, Text } from '../../../../storyBook';
import { FiCrop } from 'react-icons/fi';
import theme from '../../../../theme';
import { CenterText } from '../../../_common/style';

interface ControlPanelProps {
  setIsEditPanelOpen: (isEditPanelOpen: boolean) => void;
  setHideEditIcon: (hideEditIcon: boolean) => void;
  saveCroppedImage: () => void;
  resetToOriginal: () => void;
  setIsCropMode: (isCropMode: boolean) => void;
  isCropMode: boolean;
  cropSize?: string | number;
}

const ControlPanel = ({
  setIsEditPanelOpen,
  setHideEditIcon,
  saveCroppedImage,
  resetToOriginal,
  setIsCropMode,
  isCropMode,
  cropSize,
}: ControlPanelProps) => {
  const handleCancelButton = () => {
    setIsEditPanelOpen(false);
    setHideEditIcon(true);
    setIsCropMode(false);
  };

  const handleCropClick = () => {
    setIsCropMode(true);
  };

  return (
    <>
      <Box>
        <CenterText>
          <Text as="small" color={theme.colors.mediumDark}>
            {cropSize && `Crop size: ${cropSize}`}
          </Text>
        </CenterText>
      </Box>
      <Flex
        flexDirection="column"
        padding="10px"
        backgroundColor={theme.colors.ultraLight}
      >
        <Box onClick={handleCropClick} style={{ cursor: 'pointer' }}>
          <Flex
            justifyContent="flex-start"
            flexDirection="column"
            pb={3}
            pl={2}
          >
            <FiCrop size="16px" />
            <Text as="small">Crop</Text>
          </Flex>
        </Box>

        <Flex justifyContent="space-between">
          <Box width="100px">
            <Button
              styleType="secondaryOutline"
              size="small"
              bold
              onClick={handleCancelButton}
            >
              Cancel
            </Button>
          </Box>
          <Flex>
            {isCropMode && (
              <Box width="100px" pr={2}>
                <Button
                  styleType="primary"
                  size="small"
                  bold
                  onClick={resetToOriginal}
                >
                  Revert
                </Button>
              </Box>
            )}
            <Box width="100px">
              <Button
                styleType="primary"
                size="small"
                bold
                onClick={saveCroppedImage}
                disabled={!isCropMode}
              >
                Save
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ControlPanel;
